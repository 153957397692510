@import "./variables.less";

nz-collapse.ant-collapse {
  --header-grey: #f8f8f8;
  background: #fff;
  & > nz-collapse-panel.ant-collapse-item {
    border-color: red;
    & > .ant-collapse-header {
      padding: 5px 10px 5px 4px;
      margin-bottom: 18px;
      font-family: @bold-font-family;
      font-size: @font-sizes[base];
      color: @color-text;
      background: var(--header-grey);
      border-radius: 6px;
      display: flex;
      align-items: center;
      position: relative;

      &:after {
        content: 'Click to expand';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: @font-sizes[small];
        font-family: @regular-font-family;
        color: #999;
        padding: 0 3px;
        background: var(--header-grey);
        opacity: 0;
        transition: opacity 100ms ease;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
    &:last-child {
      > .ant-collapse-header {
        border-radius: 6px;
      }
    }
    &.ant-collapse-item-active {
      & > .ant-collapse-header {
        &:after {
          content: 'Click to collapse';
        }
      }
    }
    & > .ant-collapse-content {
      & > .ant-collapse-content-box {
        padding: 0 0 16px;
      }
      &:last-child {
        & > .ant-collapse-header {
          border-radius: 6px;
        }
      }
    }
  }

}
